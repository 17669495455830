import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import style from "./map.module.css";
import {Showtoast} from "../utils/utils";


const MapComponent = () => {
  const mapRef = useRef();
  const libraries = ["places", "drawing"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error('Error getting user location:', error);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }, []);

  const [currentPosition, setCurrentPosition] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(15);
  const [getCordinate, setCordinate] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
 
const fetchApiData = useCallback(() => {
  currentPosition?.lat&&zoomLevel-4>=7&&fetch(
    `https://devpy.service.airtelftth.innovationm.com/api/filtered/?center_latitude=${currentPosition?.lat}&center_longitude=${currentPosition?.lng}&zoom_level=${zoomLevel-4}`
  )
    .then((response) => response.json())
    .then((data) => {
      const newData = data.objects?.map((item) => {
        const data = { ...item };
        delete data.Lat;
        delete data.Long;
        return {
          ...data,
          lat: parseFloat(item.Lat),
          lng: parseFloat(item.Long),
        };
      });
      setCordinate(newData);
    });
}, [currentPosition, zoomLevel]);

useEffect(() => {

  fetchApiData();
  
}, [fetchApiData]);

  

  

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
  };


  const handleZoomChanged = () => {
    if (mapRef.current) {
      let currentZoomLevel = mapRef.current.getZoom();
      if(currentZoomLevel>=7){
        setZoomLevel(currentZoomLevel);
      }
      else{
        setCordinate([]);
        Showtoast("Zoom level should be greater than 7")
      
      }
      const currentCenter = mapRef.current.getCenter();
      setCurrentPosition({ lat: currentCenter.lat(), lng: currentCenter.lng() });
    }
  };
  const handleCenterChanged = () => {
    if (mapRef.current) {
      const currentCenter = mapRef.current.getCenter();
          setCurrentPosition({ lat: currentCenter.lat(), lng: currentCenter.lng() });
    }
  };

  const fatBoxOptions = [
    { value: 1, label: "FatBox" },
    { value: 2, label: "FatBox 2" },
  ]

  return isLoaded ? (
    <div id="map">
      <GoogleMap
        zoom={zoomLevel}
        center={currentPosition }
        onLoad={onLoadMap}
        onZoomChanged={handleZoomChanged}
        onDragEnd={handleCenterChanged}
        mapContainerStyle={containerStyle}
        options={{
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false, // To hide the map type control (satellite, roadmap, etc.)
  }}
    
      >
      <select className={style.select} onChange={(e)=>{
        setZoomLevel(e.target.value)
      }}>
      {fatBoxOptions.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
      </select>

        {getCordinate?.map((coord, index) => (
          <Marker key={`marker-${index}`} position={coord} onClick={()=>{
            setSelectedMarker(coord)
          }}/>
        ))} 
        {selectedMarker ? (
              <InfoWindow
                position={selectedMarker}
                onCloseClick={() => setSelectedMarker(null)}
                className={style.infoWindow}
              >
                <>
                  <div className={style.box}>
                    <p className={style.heading}>Circle:</p>{" "}
                    <p className={style.value}>{selectedMarker.Circle}</p>
                  </div>
                  <div className={style.box}>
                    <p className={style.heading}>City Name:</p>{" "}
                    <p className={style.value}>{selectedMarker.City}</p>
                  </div>
                  <div className={style.box}>
                    <p className={style.heading}>RSU:</p>
                    <p className={style.value}>
                      {selectedMarker.RSU}
                    </p>
                  </div>
                  <div className={style.box}>
                    <p className={style.heading}>City Fat:</p>
                    <p className={style.value}>{selectedMarker.City_FAT}</p>
                  </div>
                  <div className={style.box}>
                    <p className={style.heading}>FAT:</p>{" "}
                    <p className={style.value}>{selectedMarker.FAT}</p>
                  </div>
                </>
              </InfoWindow>
            ) : null}
      </GoogleMap>
      
    </div>
  ) : null;
};

export default MapComponent;
